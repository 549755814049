const translationsArray = [];
// PLAYER INFO
const playerInfo = {
    currency: '',
    currencySymbol: '',
    availableToWithdraw: '',
    email: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    country: '',
    city: '',
    address: '',
    postCode: '',
    phone: '',
    level: '',
    levelPercent: '',
    points: '',
    levelImageUrl: '',
    nextLevelPoints: '',
    currentLevelPoints: '',
    token: '',
    totalDepositAmounts: '',
    apiId: '',
};

const setPlayerInfo = (values) => {
    Object.assign(playerInfo, values);
}
// PAYMENT
const paymentInfo = {
    currency: '',
    currencySymbol: '',
    currencyConverted: '',
    id: '',
    min: '',
    max: '',
    name: '',
    amount: '',
    amountCoverted: '',
    savedProfiles: [],
    savedProfilesId: '',
    bonusCode: '',
    imageUrl: '',
    methodFields: [],
    playerFields: [],
    amountFieldName: '',
    firstStep: true,
    lastUsedMethod: '>',
    reset: function() {
        Object.assign(this, {
            currency: playerInfo.currency,
            currencyConverted: '',
            id: '',
            min: '',
            max: '',
            name: '',
            amount: '',
            amountCoverted: '',
            savedProfiles: [],
            savedProfilesId: '',
            bonusCode: '',
            imageUrl: '',
            methodFields: [],
            playerFields: [],
            amountFieldName: '',
            firstStep: true,
        });
    },
    set: function(newValues) {
        if (newValues.hasOwnProperty('reset')) delete newValues.reset;
        if (newValues.hasOwnProperty('set')) delete newValues.set;
        Object.assign(this, newValues);
    }
};

const playerAccounts = [];

// DEPOSIT
const depositModal = {
    steps: {
        paymentMethods: 1,
        amount: 2,
        fillPlayerInfo: 3,
        savedAccounts: 4,
        fillMethodInfo: 5,
    }
};

// GAME INFO
const gameInfo = {
    missedFields: []
};

// BONUSES
const firstBonusCurrenciesData = {
    "EUR": {
        "min": 15,
        "max": 200
    },
    "USD": {
        "min": 15,
        "max": 200
    },
    "CAD": {
        "min": 20,
        "max": 270
    },
    "AUD": {
        "min": 25,
        "max": 300
    },
    "NZD": {
        "min": 30,
        "max": 330
    },
    "PLN": {
        "min": 90,
        "max": 920
    },
    "INR": {
        "min": 1500,
        "max": 16000
    },
    "NOK": {
        "min": 150,
        "max": 2100
    },
    "BCH": {
        "min": 0.17,
        "max": 1.69
    },
    "BTC": {
        "min": 0.001,
        "max": 0.01
    },
    "LTC": {
        "min": 0.37,
        "max": 3.7
    },
    "ETH": {
        "min": 0.015,
        "max": 0.15
    },
    "DOG": {
        "min": 323.98,
        "max": 3260.21
    },
    "XRP": {
        "min": 43.97,
        "max": 439.68
    },
    "USDT": {
        "min": 20,
        "max": 200
    },
    "ADA": {
        "min": 44.8,
        "max": 448
    },
    "TRX": {
        "min": 325.77,
        "max": 3269.75
    },
    "BNB": {
        "min": 0.069,
        "max": 0.69
    }
},
secondBonusCurrenciesData = {
    "EUR": {
        "min": 15,
        "max": 300
    },
    "USD": {
        "min": 15,
        "max": 300
    },
    "CAD": {
        "min": 20,
        "max": 400
    },
    "AUD": {
        "min": 25,
        "max": 450
    },
    "NZD": {
        "min": 30,
        "max": 500
    },
    "PLN": {
        "min": 85,
        "max": 1400
    },
    "INR": {
        "min": 1500,
        "max": 25000
    },
    "NOK": {
        "min": 150,
        "max": 3000
    },
    "BCH": {
        "min": 0.2,
        "max": 60
    },
    "BTC": {
        "min": 0.00075,
        "max": 0.3
    },
    "LTC": {
        "min": 0.4,
        "max": 130
    },
    "ETH": {
        "min": 0.02,
        "max": 6
    },
    "DOG": {
        "min": 300,
        "max": 90000
    },
    "XRP": {
        "min": 60,
        "max": 18000
    },
    "USDT": {
        "min": 20,
        "max": 300
    },
    "ADA": {
        "min": 40,
        "max": 12000
    },
    "TRX": {
        "min": 300,
        "max": 90000
    },
    "BNB": {
        "min": 0.08,
        "max": 24
    }
},
thirdBonusCurrenciesData = {
    "EUR": {
        "min": 20,
        "max": 500
    },
    "USD": {
        "min": 20,
        "max": 500
    },
    "CAD": {
        "min": 25,
        "max": 680
    },
    "AUD": {
        "min": 30,
        "max": 750
    },
    "NZD": {
        "min": 35,
        "max": 830
    },
    "PLN": {
        "min": 90,
        "max": 2300
    },
    "INR": {
        "min": 1600,
        "max": 40800
    },
    "NOK": {
        "min": 200,
        "max": 5000
    },
    "BCH": {
        "min": 0.083,
        "max": 100
    },
    "BTC": {
        "min": 0.00072,
        "max": 0.5
    },
    "LTC": {
        "min": 0.21,
        "max": 200
    },
    "ETH": {
        "min": 0.011,
        "max": 10
    },
    "DOG": {
        "min": 326.84,
        "max": 150000
    },
    "XRP": {
        "min": 45.64,
        "max": 30000
    },
    "USDT": {
        "min": 20,
        "max": 10000
    },
    "ADA": {
        "min": 76,
        "max": 20000
    },
    "TRX": {
        "min": 282.47,
        "max": 150000
    },
    "BNB": {
        "min": 0.09,
        "max": 40
    }
},
fourthBonusCurrenciesData = {
    "EUR": {
        "min": 50,
        "max": 1000
    },
    "USD": {
        "min": 50,
        "max": 1000
    },
    "CAD": {
        "min": 70,
        "max": 1350
    },
    "AUD": {
        "min": 75,
        "max": 1500
    },
    "NZD": {
        "min": 80,
        "max": 1650
    },
    "PLN": {
        "min": 230,
        "max": 4500
    },
    "INR": {
        "min": 4000,
        "max": 83400
    },
    "NOK": {
        "min": 450,
        "max": 10000
    },
    "BCH": {
        "min": 0.39,
        "max": 200
    },
    "BTC": {
        "min": 0.0026,
        "max": 1
    },
    "LTC": {
        "min": 0.54,
        "max": 400
    },
    "ETH": {
        "min": 0.035,
        "max": 20
    },
    "DOG": {
        "min": 422,
        "max": 300000
    },
    "XRP": {
        "min": 114,
        "max": 60000
    },
    "USDT": {
        "min": 45,
        "max": 20000
    },
    "ADA": {
        "min": 137,
        "max": 40000
    },
    "TRX": {
        "min": 840,
        "max": 300000
    },
    "BNB": {
        "min": 0.15,
        "max": 80
    }
},
wednesdayBonusCurrenciesData = {
    "EUR": {
        "min": 20
    },
    "USD": {
        "min": 20
    },
    "CAD": {
        "min": 25
    },
    "AUD": {
        "min": 30
    },
    "NZD": {
        "min": 35
    },
    "PLN": {
        "min": 95
    },
    "INR": {
        "min": 1600
    },
    "NOK": {
        "min": 200
    },
    "BCH": {
        "min": 0.17
    },
    "BTC": {
        "min": 0.00097
    },
    "LTC": {
        "min": 0.36
    },
    "ETH": {
        "min": 0.013
    },
    "DOG": {
        "min": 137
    },
    "XRP": {
        "min": 43.45
    },
    "USDT": {
        "min": 20
    },
    "ADA": {
        "min": 49
    },
    "TRX": {
        "min": 319
    },
    "BNB": {
        "min": 0.061
    }
},
weekendBonusCurrenciesData = {
    "EUR": {
        "min": 20,
        "max": 300
    },
    "USD": {
        "min": 20,
        "max": 300
    },
    "CAD": {
        "min": 25,
        "max": 400
    },
    "AUD": {
        "min": 30,
        "max": 450
    },
    "NZD": {
        "min": 35,
        "max": 500
    },
    "PLN": {
        "min": 95,
        "max": 1400
    },
    "INR": {
        "min": 1600,
        "max": 25000
    },
    "NOK": {
        "min": 200,
        "max": 3000
    },
    "BCH": {
        "min": 0.17,
        "max": 60
    },
    "BTC": {
        "min": 0.00097,
        "max": 0.3
    },
    "LTC": {
        "min": 0.36,
        "max": 130
    },
    "ETH": {
        "min": 0.013,
        "max": 6
    },
    "DOG": {
        "min": 137,
        "max": 90000
    },
    "XRP": {
        "min": 43.45,
        "max": 18000
    },
    "USDT": {
        "min": 20,
        "max": 300
    },
    "ADA": {
        "min": 49,
        "max": 12000
    },
    "TRX": {
        "min": 319,
        "max": 90000
    },
    "BNB": {
        "min": 0.061,
        "max": 24
    }
};
const valentinesBonusCurrenciesData = {
    "EUR": {
        "min": 20
    },
    "USD": {
        "min": 20
    },
    "CAD": {
        "min": 25
    },
    "AUD": {
        "min": 30
    },
    "NZD": {
        "min": 30
    },
    "PLN": {
        "min": 95
    },
    "NOK": {
        "min": 215
    },
    "INR": {
        "min": 1760
    },
    "BCH": {
        "min": 0.17
    },
    "BTC": {
        "min": 0.00098
    },
    "LTC": {
        "min": 0.23
    },
    "ETH": {
        "min": 0.014
    },
    "DOG": {
        "min": 259.99
    },
    "XRP": {
        "min": 57.45
    },
    "USDT": {
        "min": 20
    },
    "ADA": {
        "min": 50
    },
    "TRX": {
        "min": 332.49
    },
    "BNB": {
        "min": 0.072
    }
};
let firstBonusCode, secondBonusCode, thirdBonusCode, fourthBonusCode, wednesdayBonusCode, weekendBonusCode, valentinesBonusCode;